import { useContext, useEffect, useState } from "react";
import "./NewIncident.scss";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
  TextareaDS,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../../contexts/i18n.context";
import { IncidentModal } from "../../../../contexts/ContextGetlife";

export const incidentsType = (translate: (key:string) => string) => [
  {
    label: translate("newIncident.option.changePolicy"), value: translate("newIncident.option.changePolicy")
  },
  {
    label: translate("newIncident.option.payment"), value: translate("newIncident.option.payment")
  },
  {
    label: translate("newIncident.option.cancel"), value: translate("newIncident.option.cancel")
  },
  {
    label: translate("newIncident.option.leadBlocked"), value: translate("newIncident.option.leadBlocked")
  },
  {
    label: translate("newIncident.option.errors"), value: translate("newIncident.option.errors")
  },
  {
    label: translate("newIncident.option.others"), value: translate("newIncident.option.others")
  }
]

interface NewIncidentModalProps {
  open: boolean;
  setOpen: (newIncidentModal: IncidentModal) => void;
  createIncident?: (
    incidentType: string,
    text: string,
    identify?: string,
    leadId?: string
  ) => void;
  leadId: string
  lang?: string;
  issueCreated: boolean,
  setIssueCreated: (issueCreated: boolean) => void;
}

const NewIncidentModal = ({
  open,
  setOpen,
  createIncident,
  lang,
  leadId,
  issueCreated,
  setIssueCreated
}: NewIncidentModalProps): JSX.Element => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [incidentType, setIncidentType] = useState("");
  const [identify, setIdentify] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    setOpen({ open: false, leadId: "" });
    setIdentify("");
    setIncidentType("");
    setText("");
    setLoading(false);
    setIssueCreated(false)
  }


  useEffect(() => {
    setLoading(false);
  }, [open]);

  const isSendButtonDisabled =
    (!leadId && !identify && incidentType !== translate("newIncident.option.errors") && incidentType !== translate("newIncident.option.others")) || !text || !incidentType || loading;

  return (
    <ModalDS
      open={open}
      onClose={() => onCloseModal()}
      icon="NoteIcon"
      title={issueCreated ? translate("newIncident.modal.titleCreated") : translate("newIncident.modal.title", lang)}
      content={
        !issueCreated ? <>
          <div className="flex flex-col gap-[16px]">
            <SelectDS
              label={translate("newIncident.type.label")}
              placeholder={translate("newIncident.type.placeholder")}
              value={incidentType}
              onChange={(e) => setIncidentType(e.target.value)}
              options={incidentsType(translate)}
            />
            {(!leadId && (incidentType !== translate("newIncident.option.errors") && incidentType !== translate("newIncident.option.others"))) && <InputDS
              value={identify}
              onChange={(e) => setIdentify(e.target.value)}
              icon="UserSearchIcon"
              label={translate("newIncident.clientId.label", lang)}
              placeholder={translate("newIncident.clientId.placeholder", lang)}
            />}
            <TextareaDS
              label={translate("newIncident.comment.label", lang)}
              value={text}
              placeholder={translate(
                "newIncident.comment.placeholder",
                lang
              )}
              onChange={(e) => setText(e.target.value)}
              style={{ height: 200 }}
            />
          </div>
        </> : (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex justify-center items-center w-[40px] h-[40px] bg-[#ECECFE] rounded-full	">
                <GlobalIcon iconName={"CheckedIcon"} size="XS" color="#423EF4" />
              </div>
              <div className="">
                <span>{translate("newIncident.created.text1")}</span><br></br>
                <span>{translate("newIncident.created.text2")}</span>
              </div>
            </div>
          </>
        )}

      buttons={
        !issueCreated && <ButtonDS
          onClick={() => {
            setLoading(true);
            createIncident!(
              incidentType,
              text,
              identify,
              leadId,
            );
          }}
          label={translate("newIncident.button.send", lang)}
          className="max-w-[320px] ml-auto"
          disabled={isSendButtonDisabled}
          rightIcon="ArrowRightIcon"
        />
      }
    />
  );
};

export default NewIncidentModal;
