import { useContext, useEffect, useState } from "react";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import { I18nContext } from "../../contexts/i18n.context";
import { Question } from "../../api/request/Lead/Model/Response/QuestionResponse.model";
import { useNavigate } from "react-router";

const NewQuestionsController = () => {
  const { leadId, brokerId, broker, token } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = useContext(I18nContext);

  /**
   * Enabled edit button for brokerId 1 or BrokerFR except for the Velogica question.
   */
  const BROKER_ES = broker?.brokerageId === 1;
  const BROKER_FR = broker?.brokerageId === 459;
  const BROKER_IT = broker?.brokerageId === 548;

  const isGetlifeBroker = BROKER_ES || BROKER_FR || BROKER_IT;

  const navigate = useNavigate();

  const [actualQuestion, setActualQuestion] = useState<
    Question | null | undefined
  >(null);
  const [answeredQuestions, setAnsweredQuestions] = useState<Question[] | []>(
    []
  );
  const [isEditActive, setIsEditActive] = useState<number | null>(null);
  const [hasTelesubcription, setHasTelesubscription] = useState<boolean>(false);
  const [code, setCode] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(false);

  const leadRepository = new HttpLeadRepository(token);

  const getQuestion = async (isFirst?: boolean) => {
    try {
      const question = await leadRepository.questionsByLeadId(leadId);
      setLoading(false);
      setActualQuestion(structuredClone(question.question));
      setCode(question.code);
      setHasTelesubscription(question.telesubscription);
      if (!isFirst) {
        let index = question.answeredQuestions.length - 1;
        let newAnswered = [
          ...answeredQuestions,
          question.answeredQuestions[index],
        ];
        setAnsweredQuestions(newAnswered);
      } else {
        setAnsweredQuestions([]);
        setAnsweredQuestions(question.answeredQuestions);
      }
    } catch (e) {
      throw e;
    }
  };

  const saveQuestion = async (questionID: number, answerResponse: any) => {
    let data = {
      leadId: leadId,
      brokerId: brokerId,
      questionId: questionID,
      answer: answerResponse,
    };
    try {
      await leadRepository.saveQuestionByLead(data);
      setLoading(true);
      setActualQuestion(undefined);
      if (isEditActive) {
        getQuestion(true);
        setIsEditActive(null);
      } else {
        getQuestion(false);
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const toggleEditAnswer = (questionId: number) => {
    setIsEditActive(questionId);
    const newActualQuestion: Question | undefined = answeredQuestions.find(
      (q) => q.questionId === questionId
    );
    if (newActualQuestion) {
      setActualQuestion(newActualQuestion);
    }
  };

  useEffect(() => {
    getQuestion(true);
  }, []);

  return {
    actualQuestion,
    translate,
    saveQuestion,
    answeredQuestions,
    isEditActive,
    setIsEditActive,
    isGetlifeBroker,
    code,
    hasTelesubcription,
    navigate,
    toggleEditAnswer,
    loading,
  };
};

export default NewQuestionsController;
